<template>
  <section>
    <div class="relative w-full mx-auto px-4 sm:px-6 background-gredient 2xl:h-auto lg:h-screen h-auto">

      <!-- Particles animation -->
      <ParticlesComponent class="absolute inset-0 -z-10" />

      <!-- Illustration -->
      <!-- <div class="absolute inset-0 -z-10 -mx-28 rounded-b-[3rem] pointer-events-none overflow-hidden"
        aria-hidden="true">
        <div class="absolute left-1/2 -translate-x-1/2 bottom-0 -z-10">
          <img src="@/assets/images/stellar/glow-bottom-blue.svg" class="max-w-none" width="2146" height="774"
            alt="Hero Illustration">
        </div>
      </div> -->

      <div class="  pt-12 pb-12 md:pt-20 md:pb-20 lg:px-10 md:px-5 px-2 2xl:max-h-fit lg:max-h-full max-h-fit flex items-center">

        <div class="w-full flex flex-wrap items-center max-h-full xl:mt-10 lg:mt-12 mt-4">
          <!-- Hero content -->
          <div class="w-full md:w-2/3 lg:w-1/2 mx-auto ">
            <h1
              class="xl:text-5xl/tight  md:text-3xl/normal text-2xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-white/60 via-slate-200 to-slate-200/60 lg:mb-7 mb-5"
              data-aos="fade-down">{{ $t('hero_title') }}</h1>
            <h2
              class="xl:text-[32px]/normal  md:text-[28px]/normal text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white/60 via-slate-200 to-slate-200/60 mb-4"
              data-aos="fade-down" v-html="$t('hero_subtitle')"></h2>
            <p class="text-lg/7 font-normal text-slate-300 mb-8" data-aos="fade-down" data-aos-delay="200">
              <span class="w-[862px] text-center" v-html="$t('hero_text')"></span>
            </p>

            <div class="mb-6 md:flex md:gap-6" data-aos="fade-down">
              <div class="inline-flex relative before:absolute before:inset-0 before:bg-cyan-500 before:blur-md mb-2">
                <a class="min-w-48 w-auto btn-sm h-9 py-0.5 text-slate-300 hover:text-white hover:no-underline transition duration-150 ease-in-out group [background:linear-gradient(theme(colors.slate.500),_theme(colors.slate.500))_padding-box,_linear-gradient(theme(colors.cyan.500),_theme(colors.cyan.200)_75%,_theme(colors.transparent)_100%)_border-box]  relative before:absolute before:inset-0 before:bg-slate-800/50 before:rounded-full before:pointer-events-none shadow"
                  href="/early-invite">
                  <span class="relative inline-flex items-center">
                    {{ $t('early_wishlist_title') }} <span
                      class="tracking-normal text-[#55EDF7] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"><font-awesome-icon
                        icon="fa-solid fa-arrow-right" /></span>
                  </span>
                </a>
                
              </div>
              <a class="min-w-48 w-auto btn-sm h-9 py-0.5 text-slate-900 hover:text-800 hover:no-underline transition duration-150 ease-in-out group   relative bg-white mb-2 "
                  href="/cyber-grants">
                  <span class="relative inline-flex items-center">
                    {{ $t('btn_cyber_grants') }} <span
                      class="tracking-normal text-[#55EDF7] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"></span>
                  </span>
                </a>
            </div>
          </div>
          <div class="w-full md:w-1/3 lg:w-1/2 max-h-full">
            <img src="@/assets/images/hero-img.png" class="w-full img-zoom" alt="Hero Illustration">
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ParticlesComponent from './ParticlesComponent.vue'

export default {
  name: 'HeroSection',
  components: {
    ParticlesComponent
  }
}
</script>

<style scoped>
.background-gredient {
  background: linear-gradient(-45deg, #f9d6495a, #74FAB573, transparent);
  background-size: 400% 400%;
  animation: gradient 15s ease-in-out infinite;
  /* height: 100vh; */
}

@keyframes gradient {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%;
  }

  100% {
    background-position: 10% 0%;
  }
}

.img-zoom {
  transition: transform 1.5s ease;
}

.img-zoom:hover {
  transform: scale(1.1); /* Zoom in by 20% */
}

/* @keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
} */

/* @keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
} */
</style>