<template>
    <div className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
        <!-- Site header -->
        <HeaderSection />

        <!-- Page content -->
        <main class="grow">
            <TalkToUsHero>
                <!-- <section class="w-[90%] min-h-96 mx-auto">
                    <div class="bg-blue-500/50 "></div>

                </section> -->
                <section>
                    <div
                        class="w-11/12  min-h-96 rounded-3xl lg:flex block items-center lg:p-12 p-3 mx-auto bg-[url('@/assets/images/stellar/talk-to-us-bg.svg')] bg-cover">
                        <div class="lg:w-1/2 w-full p-2">
                            <h3
                                class="font-inter xl:text-4xl/none md:text-2xl text-xl font-bold capitalize bg-clip-text text-transparent bg-gradient-to-r from-slate-50/60 via-slate-50 to-slate-50/60 mb-8">
                                {{ $t('talk_to_us_title') }}</h3>
                            <p class="mb-4 font-inter font-medium text-white md:text-xl/7 text-base">{{ $t('talk_to_us_text') }}</p>

                            <ul class=" text-slate-300 font-normal list-disc ms-5"
                                v-for="(item, index) in $tm('talk_to_us_list')" :key="index">
                                <li class="md:text-lg/7 text-base mb-2" v-html="item"></li>
                            </ul>
                        </div>
                        <div class="lg:w-1/2 w-full py-2 lg:ps-16 ps-2 pe-0">
                            <h2
                                class="text-[32px]/snug font-medium bg-clip-text text-transparent bg-gradient-to-b from-[#7BB3F9EB] to-[#EEF2FFCC] mb-3">
                                {{ $t("early_wishlist_title") }}!</h2>
                            <p class="text-sm/5 font-normal text-slate-300 mb-4">{{ $t("early_wishlist_text") }}</p>

                            <Vueform v-model="formData" validate-on="change|step" class="vueFormContainer"
                                :endpoint="false" ref="talkToUsFormRef" @submit="submitTalkToUs($event)"
                                :display-messages="false" :display-errors="false">
                                <TextElement name="name" :placeholder="$t('label_name')" rules="required">
                                </TextElement>

                                <TextElement name="email" input-type="email" :placeholder="$t('label_email')"
                                    rules="required|email">
                                </TextElement>

                                <TextElement name="company_name" :placeholder="$t('label_company_name')"
                                    rules="required">
                                </TextElement>

                                <SelectElement name="company_size" :items="company_size_options" :native="false"
                                    :search="false" :placeholder="$t('label_company_size')" rules="required" />

                                <CheckboxElement name="newsletter_subscribe" :true-value="1" :false-value="0">
                                    {{ $t('label_receive_updates') }}
                                </CheckboxElement>

                                <ButtonElement name="button" :submits="true" full :loading="is_loading"
                                    button-class="bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-medium leading-normal ">
                                    {{ $t("btn_join_now") }}
                                </ButtonElement>


                            </Vueform>

                            <!-- {{ formData }} -->

                        </div>
                    </div>
                </section>
            </TalkToUsHero>

        </main>
        <FooterSection />
    </div>
</template>

<script>

import HeaderSection from '@/partials/HeaderSection.vue'
import TalkToUsHero from '@/partials/TalkToUsHero.vue'
import FooterSection from '@/partials/FooterSection.vue'

import axios from 'axios';

export default {
    name: 'EarlyInvite',
    components: {
        HeaderSection,
        TalkToUsHero,
        FooterSection
    },
    data() {
        return {
            formData: null,

            is_loading: false,

            company_size_options: [
                // 250 - 500, 500 - 1000, 1000 - 2,500 then 2,500+
                { label: '250-500', value: '250-500' },
                { label: '500-1000', value: '50-1000' },
                { label: '1000-2500', value: '1000-2500' },
                { label: '2500+', value: '2500+' }
            ]
        }
    },
    mounted() {
    },
    methods: {
        submitTalkToUs(e) {
            console.log("in submit talk to us")
            console.log(this.formData)
            console.log(this.$refs.talkToUsFormRef)
            // e.preventDefault() 
            this.is_loading = true

            // return;
            axios
                .post("early-program/register-client", this.formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: "PROTOSTARS",
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.$swal({
                        // title: "Title",
                        text: this.$t('talk_to_us_success_message'),
                        icon: "success",
                        confirmButtonColor: '#55B3F7',
                        customClass: {
                            confirmButton: "bg-gray-100  rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-medium leading-normal "
                        },
                        color: "#F3F4F6",

                        background: "#374151"
                        // timer: 3000,
                    }).then((r) => {
                        // console.log(r.value);
                        // this.formData = {}
                        this.$refs.talkToUsFormRef.reset()
                        this.is_loading = false
                    });


                })
                .catch((error) => {
                    // console.log(error);
                    this.$swal({
                        // title: "Title",
                        text: error.response.data.error
                            ? error.response.data.error
                            : error.response.data.message,
                        icon: "error",
                        confirmButtonColor: '#03989E',
                        customClass: {
                            confirmButton: "btn btn-primary "
                        },
                        // timer: 3000,
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false
                    });
                });

        }
    }

}
</script>

<style></style>