<template>
    <div className="flex flex-col min-h-screen overflow-hidden supports-[overflow:clip]:overflow-clip">
        <!-- Site header -->
        <HeaderSection />

        <!-- Page content -->
        <main class="grow">
            <TalkToUsHero>
                <section>
                    <div
                        class="w-11/12  min-h-96 rounded-3xl  lg:p-12 p-3 mx-auto bg-[url('@/assets/images/stellar/talk-to-us-bg.svg')] bg-cover bg-center">
                        <!-- phase 1 -->
                        <div class="w-full lg:flex block mb-2">
                            <div class="lg:w-1/2 w-full p-2">
                                <div
                                    class="mb-4 bg-clip-text text-transparent bg-gradient-to-r from-slate-50/60 via-slate-50 to-slate-50/60">
                                    <h3
                                        class="font-inter xl:text-4xl/tight md:text-2xl text-xl font-bold capitalize  mb-px">
                                        {{ $t('request_call_back_title') }}</h3>
                                </div>
                                <div
                                    class="mb-7 bg-clip-text text-transparent bg-gradient-to-r from-slate-50/60 via-slate-50 to-slate-50/60">

                                    <h4 class="font-inter font-medium  md:text-3xl/normal text-base">{{
                                        $t('request_call_back_title_phase1') }}</h4>
                                </div>

                                <div
                                    class="flex flex-col gap-8 font-inter font-normal md:text-lg/7 text:base tracking-tighter text-white">
                                    <p>{{ $t('request_call_back_text_phase1') }}</p>

                                </div>

                                <!-- <div class="mt-8">
                                    <img src="@/assets/images/request-call/NCC-logo 1.png" class="w-52" />
                                </div> -->
                            </div>
                            <div class="lg:w-1/2 w-full py-2 lg:ps-14 ps-2 pe-0">
                                <h2
                                    class="text-[32px]/snug font-bold bg-clip-text text-transparent bg-gradient-to-b to-[#7BB3F9EB] from-[#EEF2FFCC] mb-4 capitalize">
                                    {{ $t("free_intro_call_title") }}</h2>
                                <p class="text-lg/6 font-normal text-slate-300 mb-4">{{ $t("free_intro_call_text") }}
                                </p>

                                <Vueform v-model="formData" validate-on="change|step" class="vueFormContainer"
                                    :endpoint="false" ref="talkToUsFormRef" @submit="submitTalkToUs($event)"
                                    :display-messages="false" :display-errors="false">

                                    <TextElement name="company_name" :placeholder="$t('label_company_name')"
                                        rules="required">
                                    </TextElement>

                                    <TextElement name="name" :placeholder="$t('label_company_contact_name')"
                                        rules="required">
                                    </TextElement>

                                    <TextElement name="email" input-type="email"
                                        :placeholder="$t('label_company_contact_email')" rules="required|email">
                                    </TextElement>


                                    <CheckboxElement name="newsletter_subscribe" :true-value="1" :false-value="0">
                                        {{ $t('label_receive_updates') }}
                                    </CheckboxElement>

                                    <ButtonElement name="button" :submits="true" full :loading="is_loading"
                                        button-class="bg-gradient-to-b from-gray-100 to-gray-200 rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-bold leading-normal ">
                                        {{ $t("btn_submit_request") }}
                                    </ButtonElement>


                                </Vueform>

                                <!-- {{ formData }} -->

                            </div>
                        </div>
                        <!-- end phase 1-->

                        <!-- phase 2 -->
                        <div class="w-full lg:flex block mb-5">
                            <div class=" w-full p-2">
                                <div
                                    class="mb-1 bg-clip-text text-transparent bg-gradient-to-r from-slate-50/60 via-slate-50 to-slate-50/60">
                                    <h4 class="font-inter font-medium  md:text-3xl/normal text-base">{{
                                        $t('request_call_back_title_phase2') }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="w-full xl:flex block mb-2">
                            <div class="xl:w-4/12   w-full p-2">
                                <div
                                    class="flex flex-col gap-8 font-inter font-normal md:text-lg/7 text-base tracking-tighter text-white">
                                    <p>{{ $t('request_call_back_text_phase2') }}</p>
                                </div>
                            </div>
                            <div class="xl:w-8/12  w-full p-2 md:flex md:flex-row gap-2 block items-center">
                                <div
                                    class="lg:w-[45%] w-full min-h-64 h-auto py-11 px-5 mb-3 flex flex-col bg-[url('@/assets/images/request-call/phase2-bg1.svg')]  bg-center md:bg-contain bg-cover bg-no-repeat ">
                                    <img src="@/assets/images/request-call/enterprise-ireland-logo.png"
                                        alt="enterprise-ireland" title="Enterprise Ireland"
                                        class="w-9/12  mx-auto lg:mb-7 mb-4" />

                                    <h3
                                        class="text-center text-white md:text-lg/7 text-base/6 font-semibold font-inter lg:mb-7 mb-4">
                                        {{
                                            $t('phase2_card_title1') }}</h3>

                                    <ul class="list-disc list-inside md:ms-4 lg:ms-10 xl:ms-4">
                                        <li v-for="(item, index) in $tm('phase2_card_list1')" :key=index
                                            class="text-slate-300 text-sm font-normal">
                                            {{ item }}
                                        </li>
                                    </ul>
                                </div>

                                <div class="w-1/12 lg:block hidden"><img
                                        src="@/assets/images/request-call/arrow-right.png" class="w-10 mx-auto" />
                                </div>

                                <div
                                    class="lg:w-[45%] w-full min-h-64 h-auto py-11 px-5 mb-3 flex flex-col  bg-[url('@/assets/images/request-call/phase2-bg2.png')] bg-center md:bg-contain bg-cover bg-no-repeat ">
                                    <img src="@/assets/images/request-call/ncc-logo.png" alt="ncc" title="NCC"
                                        class="w-7/12 mx-auto lg:mb-7 mb-4" />

                                    <h3
                                        class="text-center text-white md:text-lg/7 text-base/6 font-semibold font-inter lg:mb-7 mb-4">
                                        {{
                                            $t('phase2_card_title2') }}</h3>
                                    <ul class="list-disc list-inside md:ms-4 lg:ms-10 xl:ms-4 ">
                                        <li v-for="(item, index) in $tm('phase2_card_list1')" :key=index
                                            class="text-slate-300 text-sm font-normal">
                                            {{ item }}
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <!-- end phase 2 -->
                    </div>
                </section>

                <section>
                    <div class="w-full  min-h-52 rounded-3xl lg:flex lg:flex-col block  lg:py-12 py-3 px-0 mx-auto">

                        <div
                            class="inline-flex text-base/normal font-medium bg-clip-text capitalize text-transparent bg-gradient-to-r from-cyan-500 to-purple-200 pb-3">
                            {{ $t('trusted_by_title') }}</div>

                        <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-6 gap-2">
                            <!--card 1 -->
                            <div
                                class="flex items-center justify-center lg:h-44 h-auto min-h-36 lg:w-auto w-11/12 bg-[url('@/assets/images/request-call/bg-1.svg')] bg-center bg-contain bg-no-repeat ">
                                <img src="@/assets/images/request-call/enterprise-ireland-logo.png"
                                    alt="enterprise-ireland" title="Enterprise Ireland" class="w-7/12 m-auto" />
                            </div>
                            <!--card 2 -->
                            <div
                                class="flex items-center justify-center  lg:h-44 h-auto min-h-36 lg:w-auto w-11/12  bg-[url('@/assets/images/request-call/bg-2.svg')] bg-center bg-contain bg-no-repeat ">
                                <img src="@/assets/images/request-call/ncsc-logo.png" alt="ncsc-logo" title="NCSC"
                                    class="w-4/12 m-auto" />
                            </div>
                            <!--card 3 -->
                            <div
                                class="flex items-center justify-center  lg:h-44 h-auto min-h-36 lg:w-auto w-11/12  bg-[url('@/assets/images/request-call/bg-3.svg')] bg-center bg-contain bg-no-repeat ">
                                <img src="@/assets/images/request-call/ncc-logo.png" alt="ncc" title="NCC"
                                    class="w-9/12 m-auto" />
                            </div>
                            <!--card 4 -->
                            <div
                                class="flex items-center justify-center  lg:h-44 h-auto min-h-36 lg:w-auto w-11/12  bg-[url('@/assets/images/request-call/bg-4.svg')] bg-center bg-contain bg-no-repeat ">
                                <img src="@/assets/images/request-call/cyber-ireland-logo.png" alt="cyber-ireland"
                                    title="Cyber Ireland" class="w-8/12 m-auto" />
                            </div>

                        </div>

                    </div>
                </section>
            </TalkToUsHero>

        </main>
        <FooterSection />
    </div>
</template>

<script>

import HeaderSection from '@/partials/HeaderSection.vue'
import TalkToUsHero from '@/partials/TalkToUsHero.vue'
import FooterSection from '@/partials/FooterSection.vue'

import axios from 'axios';

export default {
    name: 'RequestCallBack',
    components: {
        HeaderSection,
        TalkToUsHero,
        FooterSection
    },
    data() {
        return {
            formData: null,

            is_loading: false,

            company_size_options: [
                // 250 - 500, 500 - 1000, 1000 - 2,500 then 2,500+
                { label: '250-500', value: '250-500' },
                { label: '500-1000', value: '50-1000' },
                { label: '1000-2500', value: '1000-2500' },
                { label: '2500+', value: '2500+' }
            ]
        }
    },
    mounted() {
    },
    methods: {
        submitTalkToUs(e) {
            console.log("in submit talk to us")
            console.log(this.formData)
            console.log(this.$refs.talkToUsFormRef)
            // e.preventDefault() 
            this.is_loading = true

            // return;
            axios
                .post("early-program/register-client", this.formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: "PROTOSTARS",
                    },
                })
                .then((response) => {
                    console.log(response);
                    this.$swal({
                        // title: "Title",
                        text: this.$t('talk_to_us_success_message'),
                        icon: "success",
                        confirmButtonColor: '#55B3F7',
                        customClass: {
                            confirmButton: "bg-gray-100  rounded-lg text-center !border-gray-100 !text-gray-800 text-md font-medium leading-normal "
                        },
                        color: "#F3F4F6",

                        background: "#374151"
                        // timer: 3000,
                    }).then((r) => {
                        // console.log(r.value);
                        // this.formData = {}
                        this.$refs.talkToUsFormRef.reset()
                        this.is_loading = false
                    });


                })
                .catch((error) => {
                    // console.log(error);
                    this.$swal({
                        // title: "Title",
                        text: error.response.data.error
                            ? error.response.data.error
                            : error.response.data.message,
                        icon: "error",
                        confirmButtonColor: '#03989E',
                        customClass: {
                            confirmButton: "btn btn-primary "
                        },
                        // timer: 3000,
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false
                    });
                });

        }
    }

}
</script>

<style></style>